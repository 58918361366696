<template>
  <sign-page
    title-text="操作记录"
    :request="request"
    table-size="large"
    :column-list="columnList"
    :initSearchData="initSearchData"
    :search-parms="searchParms"
    :dealSearchParm="dealSearchParm"
    :table-actions="[]"
    :title-menus="[]"
    :table-actions-fixed="true"
    :tableActionsWidth="100"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  operationLogRequest as request
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  methods: {
    async loadData (parm) {
      let datas = await request.get(parm)
      datas.forEach(v => {
        v.allPath = v.method + ' ' + v.path
        v.isSuccessText = v.isSuccess ? '成功' : '失败'
      })
      return datas
    },
    dealSearchParm (parm) {
      let newParm = {}
      newParm.username = parm.username
      newParm.isSuccess = parm.isSuccess
      if (parm.timeRange) {
        newParm.startTime = dateOperating.computeDay({days: 0, date: parm.timeRange[0], format: 'yy-mm-dd'})
        newParm.endTime = dateOperating.computeDay({days: 1, date: parm.timeRange[1], format: 'yy-mm-dd'})
      }
      newParm.historyTableName = parm.historyTableName
      return newParm
    }
  },
  computed: {
    initSearchData () {
      return {
        username: '',
        timeRange: [dateOperating.computeDay({days: -1, date: new Date()}), dateOperating.computeDay({days: 0, date: new Date()})]
      }
    },
    searchParms () {
      return [{
        type: 'select',
        placeholder: '操作数据',
        key: 'historyTableName',
        selectDatas: [{
          key: 'history_org',
          label: '机构'
        },
        {
          key: 'history_position',
          label: '职务'
        },
        {
          key: 'history_worker',
          label: '员工'
        },
        {
          key: 'history_train',
          label: '培训'
        },
        {
          key: 'history_rew_pun',
          label: '奖励处分'
        },
        {
          key: 'history_his_work',
          label: '工作记录'
        },
        {
          key: 'history_worker_edu',
          label: '教育经历'
        },
        {
          key: 'history_agreement',
          label: '合同'
        },
        {
          key: 'history_worker_certificate',
          label: '证件'
        },
        {
          key: 'history_wage',
          label: '工资'
        },
        {
          key: 'history_worker_job_title',
          label: '职称聘任记录'
        },
        {
          key: 'history_ask_leave',
          label: '请假记录'
        }]
      },
      {
        type: 'input',
        placeholder: '操作人',
        key: 'username'
      },
      {
        type: 'dateRange',
        placeholder: '操作日期',
        key: 'timeRange',
        default: [dateOperating.computeDay({days: -1, date: new Date()}), dateOperating.computeDay({days: 0, date: new Date()})]
      },
      {
        type: 'select',
        placeholder: '是否成功',
        key: 'isSuccess',
        selectDatas: [{
          key: '1',
          label: '成功'
        },
        {
          key: '0',
          label: '失败'
        }]
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '操作名称',
          sort: true,
          field: 'name'
        },
        {
          title: '操作人',
          sort: true,
          field: 'username'
        },
        {
          title: '操作开始时间',
          sort: true,
          field: 'startTime'
        },
        {
          title: '操作结束时间',
          sort: true,
          field: 'endTime'
        },
        {
          title: 'API',
          sort: true,
          field: 'allPath'
        },
        {
          title: '是否成功',
          sort: true,
          field: 'isSuccessText'
        // },
        // {
        //   title: '参数',
        //   sort: true,
        //   field: 'params'
        // },
        // {
        //   title: '失败说明',
        //   sort: true,
        //   field: 'error'
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      }
    }
  }
}
</script>
